import { Button } from "@chakra-ui/button"
import React from "react"

export interface ZiptiButtonProps {
  position?: string
  mt?: string
  w?: string
  h?: string
  left?: string
  top?: string
  borderRadius?: string
  backgroundColor?: string
  zIndex?: string
  onClick?: any
  children: React.ReactNode
  bottom?: string
  right?: string
  width?: string
  fontSize?: string
  opacity?: string
}

const ZiptiButton: React.FC<ZiptiButtonProps> = props => {
  return (
    <Button
      h="2.5rem"
      w="9rem"
      borderRadius="24px"
      color="white"
      fontSize="12px"
      bgColor="#4329AE"
      align="center"
      cursor="pointer"
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default ZiptiButton
