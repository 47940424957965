import { Container } from "@chakra-ui/layout"
import { navigate } from "gatsby"
import React from "react"

import ZiptiButton from "../components/shared/zipti_button"
import FacebookLoginWrapper from "../components/facebook_login_wrapper"

const LoginPage = props => {
  return (
    <FacebookLoginWrapper
      onLogin={() => {
        navigate("/home")
      }}
    >
      <Container centerContent>
        <ZiptiButton mt="25vh" w="80vw">
          Continue with Facebook
        </ZiptiButton>
      </Container>
    </FacebookLoginWrapper>
  )
}

export default LoginPage
